import { Box } from '@mui/system';

import good1 from 'assets/images/card/good6-detail-1.png';

const mm = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' }, lineHeight: '1.5rem' }}>
        <div id="post-56" className="entry-content">
            <div id="shohin">
                <p>
                    特定保健用食品(トクホ)でも使用されている素材である、イワシペプチドを中心に DHA・EPA
                    が豊富なマグロ魚油やカツオペプチド、イチョウ葉などを配合しました。
                    <br />
                    「毎日のサラサラ生活」や「最 近物忘れが・・・」なんて方にもおすすめです。
                </p>
                <div className="shohinimg">
                    <img className="fullWidth" src={good1} width="950" height="700" alt="話題のミラーズマジックマスク" />
                </div>
                <p>
                    <b>■商品名</b>
                    <br />
                    梗圧順（こうあつじゅん）
                    <br />
                    <b>■内容量</b>
                    <br />
                    475mg×90粒
                    <br />
                    <b>■メーカー希望小売価格</b>
                    <br />
                    5,200円(税抜)
                    <br />
                    <b>■原材料</b>
                    <br />
                    オリーブ油、イワシペプチド、マグロ魚油、カツオエキス、カツオ節粉末、モズク抽出物、海苔ペプチド、イチョウ葉エキス、ゼラチン、グリセリン、ミツロウ、グリセリン脂肪酸エステル
                    <br />
                    <b>■成分特徴（1粒中 内容量300mg中）</b>
                    <br />
                    イワシペプチド（35mg）･･･血圧が高めの方の特定保健用食品素材としても有名。イワシペプチドに多く含まれるバリルチロシンには血圧を下げる働きがあるといわれています。イワシの身のたんぱく質に含まれますが、一般的にペプチドの状態でサプリメントなどから摂取する方が望ましいとされています。
                    <br />
                    ハイブリッドマグロ魚油（35mg）･･･化学的な処理をしない抽出したマグロ魚油。DHA・EPA・ビタミンEなどが豊富に含まれ、血液をサラサラにする働きが期待できます。
                    <br />
                    カツオペプチド（35mg）･･･イワシと同様に血圧が高めの方の特定保健用食品素材としても有名です。
                    <br />
                    イチョウ葉（9mg）･･･イチョウ葉エキスの中にふくまれるポリフェノールの一種であるフラボノイドには強力な抗酸化作用があり、ギンコライドには血液の流れを良くする働きがあります。
                    <br />
                    <b>■特 長･セールスポイント</b>
                    <br />
                    楽糖流は、血糖値が気になる方やおなかの調子を整えたい方におすすめのサプリメントです。
                    <br />
                    特定保健用食品（トクホ）素材としても使用されている難消化デキストリンに、ビタミン・ミネラルなどの栄養素も豊富な小麦ふすま、植物によってつくられる天然オリゴ糖の一種であるイヌリン、ベータグルカンも豊富な大麦粉末などの植物繊維をバランス良く配合しました。
                    <br />
                    ついつい甘いものを食べてしまう方や炭水化物が多い方など、毎日の健康維持のサポートにお役立てください。
                    <br />
                    <b>■お召し上がり方</b>
                    <br />
                    栄養補助食品として、1日1～3包を目安にそのまま、水またはぬるま湯などと一緒にお召し上がりください。
                    <br />
                </p>
                <p>
                    <b>■ご注意</b>
                    <br />
                    ・体質・体調に合わない時は、ご使用を一時中止してください。
                    <br />
                    ・原材料をご参照の上、アレルギーがある方はご使用をお止めください。
                    <br />
                    ・薬を服用中、通院中の方及び妊娠、授乳中の方は、医師とご相談の上、お飲みください。
                    <br />
                    ・本品は、多量摂取により疾病が治癒したり、より健康が増進するものではありません。1日の摂取目安量を守ってください。
                    <br />
                    ・食生活は、主菜、副菜を基本に、食事のバランスを。
                </p>
            </div>
        </div>
    </Box>
);
export default mm;
