// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-3/1.jpg';

const case3Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography>
            1棟賃貸マンションや賃貸中の区分マンション等を買い取り、時間経過により経年劣化、汚損、破損した部分を修繕したり、必要に応じてバリューアップ工事をおこないます。
            <br />
            <br />
        </Typography>
        <Typography>
            賃貸物件の空室が生じている場合には、各居室のイメ一ジチェンジを図り、入居者を早期に見つけるよう賃貸募集についても対策をして早期の満室稼働を目指します。
            <br />
            <br />
        </Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mb: 6 }} />
    </Box>
);

export default case3Page;
