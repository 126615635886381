import { Box } from '@mui/system';

import good1 from 'assets/images/card/good5-detail-1.png';

const mm = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' }, lineHeight: '1.5rem' }}>
        <div id="post-56" className="entry-content">
            <div id="shohin">
                <p>
                    血糖値や中性脂肪、おなかの調子を整えたい方におすすめ。
                    <br />
                    特定保健用食品(トクホ)素材としても使用されている難消化デキストリンに、ビタミン・ミネラルなどの栄養素も豊富な小麦ふすま、植物によってつくられる天然オリゴ糖の一種であるイヌリン、ベータグルカンも豊富な大麦粉末などの植物繊維をバランス良く配合しました。
                </p>
                <div className="shohinimg">
                    <img className="fullWidth" src={good1} width="950" height="700" alt="話題のミラーズマジックマスク" />
                </div>
                <p>
                    <b>■商品名</b>
                    <br />
                    楽糖流（らくとうりゅう）
                    <br />
                    <b>■内容量</b>
                    <br />
                    2g×90包
                    <br />
                    <b>■メーカー希望小売価格</b>
                    <br />
                    6,600円(税抜)
                    <br />
                    <b>■原材料</b>
                    <br />
                    難消化性デキストリン、小麦ふすま焙煎末、大麦粉末、イヌリン、ブドウ種皮抽出物、リンゴ抽出物、緑茶抽出物、サラシアエキス末、納豆菌培養エキス
                    <br />
                    <b>■成分特徴（1粒中 内容量300mg中）</b>
                    <br />
                    難消化デキストリン（1,000mg）･･･特定保健用食品素材としても有名な水溶性食物繊維。①食後の血糖値の上昇を穏やかにする。②おなかの調子を整える。③中性脂肪やコレステロールの低減などの効果が期待できる素材です。
                    <br />
                    小麦ふすま（600mg）･･･小麦粒の表皮部分。小麦粒からふすまと胚芽が取り除かれた（精製された）ものが「小麦粉」。小麦ふすまには食物繊維、鉄分、カルシウム、マグネシウム、亜鉛、銅などの栄養成分が豊富に含まれています。
                    <br />
                    イヌリン（100mg）･･･イヌリン植物性多糖類の一種です。糖の吸収を抑制し血糖値の上昇を抑える働きがあります。また、善玉菌を増やし、腸内の環境を整える効果も期待できます。
                    <br />
                    大麦粉末（300mg）･･･通常の大麦に比べベータグルカンを2倍以上含有した大麦です。血糖値の上昇抑制や免疫アップなどにおすすめの素材です。
                    <br />
                    <b>■特 長･セールスポイント</b>
                    <br />
                    梗圧順は、血圧が気になる方におすすめのサプリメントです。
                    <br />
                    特定保健用食品（トクホ）素材としても使用されているイワシペプチドを中心に、DHA・EPAが豊富なマグロ魚油やカツオペプチド、イチョウ葉などを配合しました。「毎日のサラサラ生活」や「最近物忘れが･･･」なんて方にもおすすめです。
                    <br />
                    <b>■お召し上がり方</b>
                    <br />
                    栄養補助食品として、1日1～3粒を目安にそのまま、水またはぬるま湯などと一緒にお召し上がりください。
                    <br />
                </p>
                <p>
                    <b>■ご注意</b>
                    <br />
                    ・体質・体調に合わない時は、ご使用を一時中止してください。
                    <br />
                    ・原材料をご参照の上、アレルギーがある方はご使用をお止めください。
                    <br />
                    ・薬を服用中、通院中の方及び妊娠、授乳中の方は、医師とご相談の上、お飲みください。
                    <br />
                    ・本品は、多量摂取により疾病が治癒したり、より健康が増進するものではありません。1日の摂取目安量を守ってください。
                    <br />
                    ・食生活は、主菜、副菜を基本に、食事のバランスを。
                </p>
            </div>
        </div>
    </Box>
);
export default mm;
