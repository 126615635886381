// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-5/1.png';
import case2 from 'assets/images/case-5/2.png';

const case5Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography sx={{ mb: 4 }}>1、通関業</Typography>
        <Typography sx={{ mb: 4 }}>2、倉庫業</Typography>
        <Typography sx={{ mb: 4 }}>3、航空運送・海上運送の代理店業</Typography>
        <Typography sx={{ mb: 4 }}>4、荷造包装業務、貨物利用運送事業</Typography>
        <Typography sx={{ mb: 2 }}>5、前記の事業に附帯または関連する一切の業務</Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mt: 6 }} />
        <img src={case2} alt="" className="fullWidth" sx={{ mt: 6 }} />
    </Box>
);

export default case5Page;
