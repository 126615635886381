import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from '../../../assets/images/logo.png'; // 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ height, handler }) => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath} onClick={() => handler('MyBoard')}>
        <img src={Logo} alt="" style={{ height }} />
    </ButtonBase>
);

export default LogoSection;
