// material-ui
import { Box } from '@mui/system';

import good1 from 'assets/images/card/good-1.png';

const jz = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' }, lineHeight: '1.5rem' }}>
        <div className="shohinimg">
            <img className="fullWidth" src={good1} alt="お酒の達人" title="お酒の達人" />
        </div>
        <p>
            <b>■メーカー</b>
            <br />
            Y.S.D
            <br />
            <b>■ブランド名</b>
            <br />
            Y.S.D
            <br />
            <b>■アイテムカテゴリ</b>
            <br />
            サプリメント・フード {'>'} サプリメント {'>'} 健康サプリメント
            <br />
            <b>■商品説明</b>
            <br />
            1袋3カプセル中にクルクミンを240mg配合した濃縮ウコン粒。お酒を飲んだ翌日がつらい、たばこを良く吸う、最近なんとなく元気がでない、最近太りやすくなった、今度の健康診断が気になる、最近顔色が悪いといわれる、そんな悩みを持つ方に。クルクミンの体内の吸収を10倍高めるバイオペリン（黒胡椒抽出物）も配合。更に豚肝臓加水分解物、Lシスチンもプラスしました。{' '}
            <br />
            <b>■飲み方</b>
            <br />
            1日3粒を目安に水またはぬるま湯などでお召し上がりください。 <br />
        </p>
        <p>
            <b>■使用上の注意</b>
            <br />
            ・原材料をご参照の上、食物アレルギーのある方はお召し上がりにならないでください。また、体調や体質によりまれに合わない場合がございます。その場合は摂取を中止してください。
            <br />
            ・薬を服用あるいは通院中の方はお医者様とご相談の上お召し上がりください。
            <br />
            ・商品により多少の色の違いや成分特有のにおいがありますが、品質に問題はありません。
            <br />
            ・乳幼児の手の届かないところに置いてください。
            <br />
            ・妊婦または授乳中の方のご使用はお避け下さい。
            <br />
            ・食生活は、主食、主菜、副菜を基本に食事のバランスを。
            <br />
            <b>■関心の高い 成分・特徴</b>
            <br />
            無着色無香料無鉱物油アルコールフリー
            <br />
            <b>■全成分</b>
            <br />
            ウコン抽出物、豚肝臓加水分解物、黒胡椒抽出物/結晶セルロース、ゼラチン、Ｌ-シスチン、ステアリン酸カルシウム、二酸化ケイ素
        </p>
    </Box>
);

export default jz;
