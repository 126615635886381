import i18next from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import enUsTrans from '../../locales/en-US.json';
import zhCnTrans from '../../locales/zh-CN.json';
import jpTrans from '../../locales/ja-JP.json';

i18next.use(initReactI18next).init({
    // 引入资源文件
    resources: {
        en: {
            translation: enUsTrans
        },
        zh: {
            translation: zhCnTrans
        },
        jp: {
            translation: jpTrans
        }
    },
    lng: 'jp',
    // 选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: 'zh',
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    }
});

export default useTranslation;
