import { Box } from '@mui/material';

import good1 from 'assets/images/card/ysd-koshiki.jpg';
import good2 from 'assets/images/card/ysd-koshiki2.jpg';
import good3 from 'assets/images/card/ysd-008-setu3.jpg';
import good4 from 'assets/images/card/ysd-008-setu4.jpg';
import good5 from 'assets/images/card/ysd-008-setu2.jpg';
import good6 from 'assets/images/card/ysd-008-setu5.jpg';

const kz = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' }, lineHeight: '1.5rem' }}>
        <div className="shohinimg">
            <img className="fullWidth" src={good1} alt="ミラーズマジック 三層構造不織布マスク 10枚入" />
            <br />
            <img className="fullWidth" src={good2} alt="ミラーズマジック 三層構造不織布マスク 50枚入（10枚×5袋）" />
        </div>
        <p>
            「ミラーズマジック(Mirrors Magic) 三層構造不織布マスク
            50枚入（10枚×5袋）」は、99％の特殊フィルター不織布（カットフィルター）を採用した、3層構造の不織布マスクです。ウィルス、細菌、花粉、ハウスダストなどをしっかり対策できます。顔にフィットする特殊立体加工で、鼻や口を圧迫せず、息苦しさを解消します。マスクは紐切れ防止加工を施してあり、衛生的に最後までご使用いただけるよう、10枚入りの密封袋を5袋と小分けに入れました。
            <br />
            <br />
            <img className="fullWidth alignnone size-full wp-image-149" src={good3} alt="ミラーズマジック説明" width="1000" height="1000" />
            <img className="fullWidth alignnone size-full wp-image-150" src={good4} alt="" width="1000" height="1000" />
            <img className="fullWidth" src={good5} alt="" width="1000" height="1000" />
            <img className="fullWidth" src={good6} alt="" width="1000" height="1000" />
        </p>
        <p>
            <b>■商品名</b>
            <br />
            ミラーズマジック三層構造不織布マスク&nbsp;&nbsp;50枚入（10枚×5袋）
            <br />
            <b>■内容量</b>
            <br />
            10枚入り、50枚入（10枚×5袋）&nbsp;&nbsp;&nbsp;&nbsp;※2種類
            <br />
            <b>■メーカー希望小売価格</b>
            <br />
            10枚入&nbsp;&nbsp;800円（税抜）&nbsp;&nbsp;&nbsp;&nbsp;50枚入&nbsp;&nbsp;3,300円（税抜）
            <br />
            <b>■サイズ</b>
            <br />
            17.5cm×9.5cm
            <br />
            <b>■カラー</b>
            <br />
            ホワイト
            <br />
            <b>■素材&nbsp;&nbsp;本体</b>
            <br />
            フィルター一部&nbsp;&nbsp;&nbsp;&nbsp;ポリプロピレン
            <br />
            ヒモ：ポリウレタン、ナイロン
            <br />
            ノーズフィッター：ポリエチレン
        </p>
        <p>
            <b>■ご使用方法</b>
            <br />
            (1)マスクの真ん中（鼻のワイヤー）の部分に折り目を入れてから上下に広げます。
            <br />
            (2)ノーズワイヤーを上にプリーツが下向きになるように持ち全体を広げます。
            <br />
            (3)顔に当てて、ノーズワイヤーを鼻のカーブに合わせます。
            <br />
            (4)余分な隙間が出ないよう、顔にフィットさせながら、耳にひもをかけご使用ください。
        </p>
        <p>
            <b>■保存方法</b>
            <br />
            ●湿気のない清潔な場所に保管してください。
            <br />
            ●乳幼児の手の届かない場所に保管して下さい。
            <br />
            ●直射日光の当たる場所での保管は避けてください。変色する恐れがあります。
        </p>
        <p>
            <b>■ご注意</b>
            <br />
            ●本品は簡易マスクですので、有害な粉塵や毒ガスなどが発生する場所では使用しないでください｡
            <br />
            ●万一臭いにより気分が悪くなった場合は、ご使用を中止してください｡
            <br />
            ●マスクのニオイがきになったり、息苦しさを感じた時や気分が悪くなった時は、使用を中止してください。
            <br />
            ●乳幼児や呼吸器に異常がある方は、使用しないでください。
            <br />
            ●個人差によリメガネが曇る場合がありますので、運転の際などは十分にご注意ください｡
            <br />
            ●耳ひもを引っ張り過ぎると切れる恐れがありますのでご注意ください。
            <br />
            ●かゆみ・かぶれ・発疹などの症状が出た場合はただちに使用を中止し、医師にご相談ください｡
            <br />
            ●本品は使い切り商品です。洗濯による再利用は出来ません｡
            <br />
            ●火気のそばでのご使用はおやめください｡
            <br />
            ●衛生面・機能面から、１日１枚のご使用をおすすめします。
            <br />
            ●一度使用したマスクは戻さないでください。
        </p>
        <p>
            <b>■製造国&nbsp;&nbsp;&nbsp;&nbsp;中国</b>
        </p>
    </Box>
);

export default kz;
