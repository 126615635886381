import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// MyBoard routing
const MyBoard = Loadable(lazy(() => import('views/MyBoard')));
const MyCard = Loadable(lazy(() => import('views/pages/cardList')));
const MyImage = Loadable(lazy(() => import('views/pages/imageList')));
const MyGoods = Loadable(lazy(() => import('views/pages/goods')));

// sample page routing
const GoodsList = Loadable(lazy(() => import('views/goodsList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <MyBoard />
        },
        {
            path: '/kaishagaiyo',
            element: <MyCard />
        },
        {
            path: '/company',
            element: <MyCard />
        },
        {
            path: '/business',
            element: <MyImage />
        },
        {
            path: '/goods',
            element: <MyGoods />
        },
        {
            path: '/product',
            element: <GoodsList />
        }
    ]
};

export default MainRoutes;
