import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, Avatar, ButtonBase, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuLang from './MenuLang';
import { drawerWidth } from 'store/constant';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useTranslation();
    const navs = [t('nav-1'), t('nav-2'), t('nav-3'), t('nav-4'), t('nav-5')];
    const footer = (
        <>
            <Box sx={{ px: 2, py: 5, color: '#333', textAlign: 'center', position: 'absolute', bottom: 0, left: 0 }}>
                <Typography sx={{ fontSize: '0.875rem', fontFamily: 'PingFangSC-Regular, PingFang SC', mb: '0.25rem' }}>
                    {t('foot-1')}
                </Typography>
                <Typography sx={{ fontSize: '1.1.25rem', fontFamily: 'PingFangSC-Medium, PingFang SC', color: '#2084DB', mb: '2rem' }}>
                    {t('foot-2')}
                </Typography>
                <Typography sx={{ fontSize: '0.875rem', fontFamily: 'PingFangSC-Regular, PingFang SC' }}>{t('foot-3')}</Typography>
            </Box>
        </>
    );
    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', sm: 'none' }, position: 'relative' }}>
                <Box sx={{ display: 'flex', mx: 'auto', position: 'fixed', right: '199px', top: 0 }}>
                    {/* 左侧展开收起按钮 */}
                    <ButtonBase
                        sx={{
                            // borderRadius: '12px',
                            overflow: 'hidden',
                            // marginLeft: drawerOpen ? '200px' : '',
                            display: { sm: 'none', xs: 'block' }
                        }}
                    >
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.largeAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                // background: 'linear-gradient(180deg, #2084DB 0%, #43BAEF 100%)',
                                // color: '#fff',
                                flexDirection: 'column',
                                borderRadius: 0,
                                '&:hover': {
                                    background: theme.palette.secondary.dark
                                    // color: theme.palette.secondary.light
                                }
                            }}
                            onClick={drawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                            <Typography sx={{ fontSize: '12px' }}>MENU</Typography>
                        </Avatar>
                    </ButtonBase>
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' }, boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)' }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto', justifyContent: 'center' }}>
                    <LogoSection height={42} />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? '100vh' : 'calc(100vh - 78px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuList navs={navs} />
                    <MenuLang />
                    {footer}
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList navs={navs} />
                    <MenuLang />
                    {footer}
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto', zIndex: 4 }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor={matchUpMd ? 'left' : 'right'}
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.2)',
                        [theme.breakpoints.up('md')]: {
                            // top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
