// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-7/1.png';
import case2 from 'assets/images/case-7/2.png';
import case3 from 'assets/images/case-7/3.png';
import case4 from 'assets/images/case-7/4.png';
import case5 from 'assets/images/case-7/5.png';
import case6 from 'assets/images/case-7/6.png';
import case7 from 'assets/images/case-7/7.png';

const case7Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography sx={{ mb: 3, fontWeight: 500 }}>鋼製品の製作</Typography>
        <Typography sx={{ mb: 3 }}>「こだわり」</Typography>

        <Typography>迅速かつ細やかな配送。</Typography>
        <Typography sx={{ mb: 3 }}>施工現場の事情やスケジュールに、最大限お応えします。</Typography>

        <Typography>安全性と品質に妥協なし。</Typography>
        <Typography>どんなに時間的な制約があろうと、職人さんに笑われる製品を私たちは作りません。</Typography>

        <Typography>何よりも、使いやすさ。</Typography>
        <Typography sx={{ mb: 5 }}>営業担当がお客様の声を、製作の現場に常にフィードバック。</Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mb: 5 }} />
        <Typography sx={{ mb: 3 }}>主要製品：</Typography>
        <Typography sx={{ mb: 3 }}>ジャケット/エルボ/バルブ/フランジ/フレキ/ボックス/</Typography>
        <Typography sx={{ mb: 3 }}>Y型ストレーナー/各種カバー/ロールキクザ/</Typography>

        <Typography sx={{ mb: 5 }}>L型コーナー/コの字コーナー/曲がりコーナーほか</Typography>
        <Box sx={{ p: 4, background: '#F5FBFB', mb: 4 }}>
            <Typography sx={{ fontWeight: 500, mb: 1, fontSize: '1.125rem' }}>· 直管（メタルジャケット）</Typography>
            <Typography sx={{ mb: 2 }}>永い経験から生まれた独自の技術により効率的な作業を可能にします。</Typography>
            <img src={case2} alt="" className="fullWidth" sx={{ mt: 3 }} />
            <Typography sx={{ mt: 2 }}>
                素材：ステンレス SUS304、カラー鋼板（各色）、ガルバリウム、ステンカラー、52S
                アルミ、亜鉛、カラーガルバリウム鋼板、スーパーダイマ
            </Typography>
            <Typography>用途：各種保温保冷材の保護（化粧）カバー</Typography>
        </Box>
        <Box sx={{ p: 4, background: '#fff', mb: 4 }}>
            <Typography sx={{ fontWeight: 500, mb: 1, fontSize: '1.125rem' }}>· 曲管（エルボ）</Typography>
            <Typography sx={{ mb: 2 }}>使いやすく施工が簡単で豊富なバリエーショ</Typography>
            <img src={case3} alt="" className="fullWidth" sx={{ mt: 3 }} />
            <Typography sx={{ mt: 2 }}>
                素材：ステンレス SUS304、カラー鋼板（各色）、ガルバリウム、ステンカラー、52S
                アルミ、亜鉛、カラーガルバリウム鋼板、スーパーダイマ
            </Typography>
            <Typography>用途：各種保温保冷材の保護（化粧）カバー</Typography>
        </Box>
        <Box sx={{ p: 4, background: '#F5FBFB', mb: 4 }}>
            <Typography sx={{ fontWeight: 500, mb: 1, fontSize: '1.125rem' }}>· スピードエルボ</Typography>
            <Typography sx={{ mb: 2 }}>ワンタッチで継ぎ施工でき、工期の短縮を可能にしています。</Typography>
            <img src={case4} alt="" className="fullWidth" sx={{ mt: 3 }} />
            <Typography sx={{ mt: 2 }}>素材：ステンレス SUS304、カラー鋼板（各色）、ガルバリウム、ステンカラー</Typography>
            <Typography>用途：各種保温保冷材の保護（化粧）カバー</Typography>
        </Box>
        <Box sx={{ p: 4, background: '#fff', mb: 4 }}>
            <Typography sx={{ fontWeight: 500, mb: 1, fontSize: '1.125rem' }}>· バルブ・フランジ・フレキ各カバー</Typography>
            <Typography sx={{ mb: 2 }}>豊富な経験に基づく高い技術力で製作する機器類は美しく使いやすいと好評です。</Typography>
            <img src={case5} alt="" className="fullWidth" sx={{ mt: 3 }} />
            <Typography sx={{ mt: 2 }}>
                素材：ステンレス SUS304、カラー鋼板（各色）、ガルバリウム、ステンカラー、52S
                アルミ、亜鉛、カラーガルバリウム鋼板、スーパーダイマ
            </Typography>
            <Typography>用途：各種保温保冷材の保護（化粧）カバー</Typography>
        </Box>
        <Box sx={{ p: 4, background: '#F5FBFB', mb: 4 }}>
            <Typography sx={{ fontWeight: 500, mb: 1, fontSize: '1.125rem' }}>· Lコーナー・曲りコーナー</Typography>
            <img src={case6} alt="" className="fullWidth" sx={{ mt: 3 }} />
            <Typography sx={{ mt: 2 }}>
                素材：亜鉛鉄板（トタン）…0.25mm、52S アルミ…0.4mm、カラー鋼板
                <br />
                使用長さ6尺、3尺 <br />
                25×25、30×30、25×50（上記サイズ以外も受注生産致します）
            </Typography>
            <Typography>用途：ダクト等の角のカバー</Typography>
        </Box>
        <Box sx={{ p: 4, background: '#fff', mb: 4 }}>
            <Typography sx={{ fontWeight: 500, mb: 1, fontSize: '1.125rem' }}>· ロール菊座</Typography>
            <Typography sx={{ mb: 2 }}>豊富な経験に基づく高い技術力で製作する機</Typography>
            <img src={case7} alt="" className="fullWidth" sx={{ mt: 3 }} />
            <Typography sx={{ mt: 2 }}>素材：ステンレス SUS304…0.2t、ステンレス SUS430…0.15t</Typography>
            <Typography>用途：継手のエンドカバー</Typography>
        </Box>
    </Box>
);

export default case7Page;
