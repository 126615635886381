// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-6/1.png';
import case2 from 'assets/images/case-6/2.png';

const case6Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography sx={{ mb: 8, fontWeight: 500 }}>
            総合リサイクル企業として、日本と中国に拠点をかまえ、プラスティック、金属などの廃棄物の収集、再資源化、そして製品化を行ってきた。その他酒事業とユニフォーム事業も行っている。
        </Typography>
        <Typography sx={{ mb: 3, textAlign: 'center' }}>金属のリサイクル</Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mt: 3 }} />
        <Typography sx={{ mt: 6, mb: 3, textAlign: 'center' }}>プラスチックのリサイクル</Typography>
        <img src={case2} alt="" className="fullWidth" sx={{ mt: 3 }} />
    </Box>
);

export default case6Page;
