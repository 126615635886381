// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-1/1.jpg';
import case2 from 'assets/images/case-1/2.jpg';

const case1Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography>
            中古区分マンションや中古住宅等を買い取りし、時間経過により経年劣化、汚損、破損して資産価値が減少した不動産にリノベ一ションを施し、今のライフスタイルに合わせたものに転換し蘇らせる事業です。
            <br />
            <br />
        </Typography>
        <Typography>
            Y.S.Dならではのご提案とスピ一ド対応により査定・買取をさせて頂き、信頼できるお取引施工業者様のご協力のもとで、質の高い住宅を提供しております。
            <br />
            <br />
        </Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mb: 6 }} />
        <img src={case2} alt="" className="fullWidth" sx={{ mb: 6 }} />
    </Box>
);

export default case1Page;
