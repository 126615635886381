import { Box } from '@mui/system';

import good1 from 'assets/images/card/ysd-004-ad1.jpg';
import good2 from 'assets/images/card/ysd-004-1.jpg';
import good3 from 'assets/images/card/ysd-004-setu_01.jpg';
import good4 from 'assets/images/card/ysd-004-setu_02.jpg';
import good5 from 'assets/images/card/ysd-004-setu_03.jpg';

const mm = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' }, lineHeight: '1.5rem' }}>
        <div id="post-56" className="entry-content">
            <div id="shohin">
                {/* <h2>
                    Mirrors Magic (ミラーズマジック）{'  '}薬用美白マスク{'  '}1P×5枚{'  '}【医薬部外品】
                </h2> */}
                <div className="shohinimg">
                    <img className="fullWidth" src={good1} width="950" height="700" alt="話題のミラーズマジックマスク" />
                    <br />
                    <img className="fullWidth" src={good2} alt="" />
                </div>
                <p>
                    「Mirrors Magic（ミラーズマジック）{'  '}フェイスマスク{'  '}1P×5枚{'  '}
                    医薬部外品」は、薬用美容液をたっぷり含ませた薬用美白マスクです。（有効成分：水溶性プラセンタエキス、グリチルリチン酸ジカリウム）（薬用美容液
                    {'  '}1袋22ml配合）（日本製）
                    <br />
                    日焼けによるシミ・そばかす・肌荒れ・ニキビを防ぎます。透明感のある、みずみずしいお肌をつくるサポートをします。国産100％のソフトコットンを使用し、無着色、無鉱物油、ノンパラベンで安心してご使用いただけます。
                    <br />
                    1袋ずつの小分けなので、旅行や出張先などへの持ち運びにも便利です。
                </p>
                <p>
                    <img
                        className="fullWidth alignnone size-full wp-image-149"
                        src={good3}
                        alt="ミラーズマジック説明"
                        width="950"
                        height="801"
                    />{' '}
                    <img
                        className="fullWidth alignnone size-full wp-image-150"
                        src={good4}
                        alt=""
                        width="950"
                        height="563"
                        // srcSet="/assets/images/card/ysd-004-setu_02.jpg 950w, /assets/images/card/ysd-004-setu_02-300x178.jpg 300w, /assets/images/card/ysd-004-setu_02-768x455.jpg 768w"
                        sizes="(max-width: 950px) 100vw, 950px"
                    />
                    <img className="fullWidth" src={good5} width="950" height="466" alt="" />
                </p>
                <p>
                    <b>■商品名</b>
                    <br />
                    Mirrors Magic（ミラーズマジック）{'　'}薬用美白マスク{'　'}1P×5枚{'　'}【医薬部外品】
                    <br />
                    <b>■内容量</b>
                    <br />
                    1P×5枚入り
                    <br />
                    <b>■メーカー希望小売価格</b>
                    <br />
                    1,200円(税抜)
                    <br />
                    <b>■成分</b>
                    <br />
                    【有効成分】水溶性プラセンタエキス、グリチルリチン酸ジカリウム
                    <br />
                    【その他の成分】精製水、1, 3-ブチレングリコール、濃グリセリン、アロエエキス(2)、クララエキス(1)
                    、クワエキス、水溶性コラーゲン液(1)、チャエキス(1)、ベニバナエキス(1)
                    、リン酸L-アスコルビルマグネシウム、ヒドロキシエチルセルロース、キサンタンガム、1,
                    2-ペンタンジオール、フェノキシエタノール、クエン酸、クエン酸ナトリウム
                    <br />
                    <b>■効能効果</b>
                    <br />
                    メラニンの生成を抑え、しみ・そばかすを防ぎます。日やけ・雪やけ後のほてりを防ぎます。肌荒れ・あれ性。
                    にきびを防ぎます。お肌をなめらかにします。
                    <br />
                    美白有効成分（水溶性プラセンタエキス）を配合した美白美容液がお肌（角質層）のすみずみまで浸透し、透明感と潤いのある美白肌へ導きます。
                    <br />
                    <b>■ご使用方法&nbsp;&nbsp;&nbsp;&nbsp;上記記載</b>
                    <br />
                    <b>■保存方法</b>
                    <br />
                    ●極端な高温または低温となる場所・直射日光のあたる場所を避け、涼しい場所で保存してください。
                    <br />
                    ●乳幼児の手の届かない場所で保存してください。
                    <br />
                    ●ご使用の際は、生活な手でご使用下さい。
                    <br />
                    ※マスクの口部分の抜け残りが入っていることがまれにありますが、取り除いてご使用下さい。
                    <br />
                </p>
                <p>
                    <b>■ご注意</b>
                    <br />
                    ・お肌に異常が生じていないかよく注意してご使用ください。
                    <br />
                    ・お肌に合わないときは、ご使用をおやめください。
                    <br />
                    ・ご使用中またはご使用後に日光に当たって、お肌に赤味・はれ・かゆみ・刺激・色抜け(白斑)や黒ずみ等の異常があらわれた場合はご使用を中止してください。そのままご使用を続けますと、症状を悪化させることがありますので、皮膚科専門医等にご相談されることをおすすめします。
                    <br />
                    ・傷やはれもの、湿疹等、異常がある部分にはご使用にならないでください。
                    <br />
                    ・マスクの目部分を閉じてご使用になる場合は、必ずまぶたを閉じてください。
                    <br />
                    ・目に入らないようにご注意ください。目に入ったときは、擦らずにすぐに水で洗い流してください。異常が残るときは、眼科医にご相談されることをおすすめします。
                    <br />
                    ・衛生上、一度ご使用になったマスクは繰り返しご使用にならないでください。
                    <br />
                    <b>■製造国&nbsp;&nbsp;&nbsp;&nbsp;日本</b>
                </p>
            </div>
        </div>
    </Box>
);
export default mm;
