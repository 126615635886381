// assets
// import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
// const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'MyBoard',
            title: 'nav-1',
            type: 'item',
            url: '/',
            // icon: icons.IconBrandChrome,
            icons: false,
            breadcrumbs: false
        },
        {
            id: 'product',
            title: 'nav-2',
            type: 'item',
            url: '/product',
            // icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'business',
            title: 'nav-3',
            type: 'item',
            url: '/business',
            // icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'company',
            title: 'nav-4',
            type: 'item',
            url: '/company',
            // icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
    ]
};

export default other;
