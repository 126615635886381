// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-2/1.jpg';
import case2 from 'assets/images/case-2/2.jpg';

const case2Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography>
            土地建物が所在する周辺権利関係が複雑な物件においても、専門家のご意見・ご協力を頂き、関係行政への相談調整等を含めて、あらゆる方向から解決策を導くように努力します。
            <br />
            <br />
        </Typography>
        <Typography>
            常に、不動産の価値をあらゆる角度から見つめ直し、弊社にしかできない経験と知識をもとに不動産の再生を実現させております。
            <br />
            <br />
        </Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mb: 6 }} />
        <img src={case2} alt="" className="fullWidth" sx={{ mb: 6 }} />
    </Box>
);

export default case2Page;
