// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-8/1.png';
// import case2 from 'assets/images/case-8/2.png';
// import case3 from 'assets/images/case-8/3.png';
import case4 from 'assets/images/case-8/4.png';
import case5 from 'assets/images/case-8/5.png';
import case6 from 'assets/images/case-8/6.png';

const case8Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography sx={{ mb: 8 }}>
            富士山の麓に自社工場を持ち、ミネラルウォーター、ナチュラルウォーター、ナチュラルミネラルウォーター、天然水の受託製造・受託加工・受託生産（委託製造・委託加工・委託生産）。
        </Typography>
        <img src={case1} alt="" className="fullWidth" sx={{ mt: 6 }} />
        {/* <img src={case2} alt="" className="fullWidth" sx={{ mt: 6 }} /> */}
        {/* <img src={case3} alt="" className="fullWidth" sx={{ mt: 6 }} /> */}
        <img src={case4} alt="" className="fullWidth" sx={{ mt: 6 }} />
        <img src={case5} alt="" className="fullWidth" sx={{ mt: 6 }} />
        <img src={case6} alt="" className="fullWidth" />
    </Box>
);

export default case8Page;
