// action - state management
import * as actionTypes from './actions';

import good1 from 'assets/images/card/good-4.png';
import good2 from 'assets/images/card/good-3.png';
import good3 from 'assets/images/card/good-2.png';
import good4 from 'assets/images/card/good-1.png';
import good5 from 'assets/images/card/good-5.png';
import good6 from 'assets/images/card/good-6.png';

import pn1 from 'assets/images/card/1.jpg';
import pn2 from 'assets/images/card/2.jpg';
import pn3 from 'assets/images/card/3.jpg';
import pn4 from 'assets/images/card/2.png';
import pn5 from 'assets/images/card/5.png';
import pn6 from 'assets/images/card/6.png';
import pn7 from 'assets/images/card/7.png';
import pn8 from 'assets/images/card/8.png';

import good1Detail from 'views/pages/goods/kz';
import good2Detail from 'views/pages/goods/fz';
import good3Detail from 'views/pages/goods/mm';
import good4Detail from 'views/pages/goods/jz';
import good5Detail from 'views/pages/goods/rtr';
import good6Detail from 'views/pages/goods/kaj';
// import good3Video from 'assets/images/detail-3.mp4';

import case1Detail from 'views/pages/goods/case-1';
import case2Detail from 'views/pages/goods/case-2';
import case3Detail from 'views/pages/goods/case-3';
import case4Detail from 'views/pages/goods/case-4';
import case5Detail from 'views/pages/goods/case-5';
import case6Detail from 'views/pages/goods/case-6';
import case7Detail from 'views/pages/goods/case-7';
import case8Detail from 'views/pages/goods/case-8';

import case1 from 'assets/images/card/case-1.png';
import case2 from 'assets/images/card/case-2.png';
import case3 from 'assets/images/card/case-3.png';
import case4 from 'assets/images/card/case-4.png';
import case5 from 'assets/images/card/case-5.png';
import case6 from 'assets/images/card/case-6.png';
import case7 from 'assets/images/card/case-7.png';
import case8 from 'assets/images/card/case-8.png';

export const initialState = {
    name: '1',
    goodsList: [
        {
            id: 'goods-1',
            img: good1,
            imgs: good1Detail,
            title: 'Mirrors Magic（ミラーズマジック）三層構造不織布マスク',
            detail: '99％カットフィルター使用。花粉、カゼの予防に。衛生面・機能性も考慮しています。'
        },
        {
            id: 'goods-3',
            img: good3,
            // video: good3Video,
            imgs: good3Detail,
            title: 'Mirrors Magic（ミラーズマジック）薬用美白マスク',
            detail: '当社は、話題の美白マスク「ミラーズマジック」の販売元です。'
        },
        {
            id: 'goods-2',
            img: good2,
            imgs: good2Detail,
            title: 'FEVEN.ZZ',
            detail: '新商品！食べるのを我慢しないで、ダイエットにチャレンジしたい方におすすめです。'
        },
        {
            id: 'goods-4',
            img: good4,
            imgs: good4Detail,
            title: 'お酒の達人',
            detail: 'お酒の達人'
        },
        {
            id: 'goods-5',
            img: good5,
            imgs: good5Detail,
            title: '楽糖流',
            detail: '血糖値や中性脂肪、おなかの調子を整えたい方におすすめ。'
        },
        {
            id: 'goods-6',
            img: good6,
            imgs: good6Detail,
            title: '梗圧順',
            detail: '「毎日のサラサラ生活」や「最近物忘れが・・・」なんて方にもおすすめです。'
        }
    ],
    currentGoods: {},
    caseList: [
        {
            id: 'case-1',
            img: pn1,
            imgs: case1Detail,
            title: 'case-1',
            address: 'case-1-1',
            headBg: pn1
        },
        {
            id: 'case-2',
            img: pn2,
            imgs: case2Detail,
            title: 'case-2',
            address: 'case-2-1',
            headBg: pn2
        },
        {
            id: 'case-3',
            img: pn3,
            imgs: case3Detail,
            title: 'case-3',
            address: 'case-3-1',
            headBg: pn3
        },
        {
            id: 'case-4',
            img: pn4,
            imgs: case4Detail,
            title: 'case-4',
            address: 'case-4-1',
            headBg: pn4
        },
        {
            id: 'case-5',
            img: pn5,
            imgs: case5Detail,
            title: 'case-5',
            address: 'case-5-1',
            headBg: pn5
        },
        {
            id: 'case-6',
            img: pn6,
            imgs: case6Detail,
            title: 'case-6',
            address: 'case-6-1',
            headBg: pn6
        }
    ],
    childCaseList: [
        {
            id: 'case-1',
            img: pn1,
            imgs: case1Detail,
            title: 'case-1',
            address: 'case-1-1',
            headBg: pn1
        },
        {
            id: 'case-2',
            img: pn2,
            imgs: case2Detail,
            title: 'case-2',
            address: 'case-2-1',
            headBg: pn2
        },
        {
            id: 'case-3',
            img: pn3,
            imgs: case3Detail,
            title: 'case-3',
            address: 'case-3-1',
            headBg: pn3
        }
    ],
    childCaseList2: [
        {
            id: 'case-4',
            img: pn4,
            imgs: case4Detail,
            title: 'case-4',
            address: 'case-4-1',
            headBg: pn4
        },
        {
            id: 'case-5',
            img: pn5,
            imgs: case5Detail,
            title: 'case-5',
            address: 'case-5-1',
            headBg: pn5
        },
        {
            id: 'case-6',
            img: pn6,
            imgs: case6Detail,
            title: 'case-6',
            address: 'case-6-1',
            headBg: pn6
        }
    ],
    currentCase: {}
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const goods = (state = initialState, action) => {
    let goods;
    let currentCase;
    switch (action.type) {
        case actionTypes.SET_GOODS:
            goods = action.goods;
            return {
                ...state,
                currentGoods: goods
            };
        case actionTypes.SET_CASE:
            currentCase = action.currentCase;
            return {
                ...state,
                currentCase
            };
        default:
            return state;
    }
};

export default goods;
