// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const MenuLang = () => {
    const langs = []; // ['日本語', '中文', 'English'];
    const customization = useSelector((state) => state.customization);
    const { t } = useTranslation();
    const [langInx, setLang] = useState('日本語');
    const theme = useTheme();
    const dispatch = useDispatch();
    const itemHandler = (lang) => {
        i18next.changeLanguage(lang);
        setLang(lang);
    };

    return (
        <div>
            {langs.map((lang, index) => (
                <ListItemButton
                    key={index}
                    sx={{
                        borderRadius: `${customization.borderRadius}px`,
                        alignItems: 'flex-start',
                        backgroundColor: 'inherit'
                    }}
                    onClick={() => itemHandler(lang)}
                >
                    <ListItemText
                        primary={
                            <Typography
                                sx={{ fontSize: '12px', textAlign: 'center' }}
                                variant="body1"
                                color={lang === langInx ? '#000' : '#999999'}
                            >
                                {lang}
                            </Typography>
                        }
                    >
                        111
                    </ListItemText>
                </ListItemButton>
            ))}
        </div>
    );
};

export default MenuLang;
