import { Box } from '@mui/system';

import good1 from 'assets/images/card/fevenZZ.jpg';

const fz = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' }, lineHeight: '1.5rem' }}>
        <div className="shohinimg">
            <img className="fullWidth" src={good1} alt="Natural Cut{'　'}FEVEN.ZZ" title="Natural Cut{'　'}FEVEN.ZZ" />
        </div>
        <p>
            キノコキトサンにサイリウム、デキストリン、オリゴ糖、ビフィズス菌などをバランスよく配合したダイエットです。
            <br />
            脂っこいお食事がお好きな方、ついつい食べ過ぎてしまう方におすすめです。
        </p>
        <p>
            <b>■商品名</b>
            <br />
            Natural Cut{'　'}FEVEN.ZZ
            <br />
            <b>■内容量</b>
            <br />
            90g{'　'}(3g×30包)
            <br />
            <b>■メーカー希望小売価格</b>
            <br />
            6,800円(税抜)
            <br />
            <b>■原材料</b>
            <br />
            デキストリン、サイリウム(プランタゴオバタ種皮末)、レシチン、トレハロース、ガラクトオリゴ糖、キノコキトサン、クエン酸、香料、サイクロデキストリン、緑茶抽出物、ビフィズス菌、ラクチュロース、甘味料（甘草）、乳酸カルシウム、植物性ステロール、L-テアニン、チコリ抽出物、ユウキ葉エキス、大豆ペプチド、ビタミンC、ナイアシン、ビタミンE、パントテン酸Ca、ビタミンB6、ビタミンB1、ビタミンA、ユッカエキス、シャンピニオンエキス末、葉酸、ビタミンD、ビタミンB12（原材料の一部に、乳由来、大豆由来を含む）
            <br />
            <b>■お召し上がり方</b>
            <br />
            栄養補助食品として1日1～2包を目安に、多めの水またはぬるま湯とともにお召し上がり下さい。
            <br />
            <b>■保存方法</b>
            <br />
            高温多湿、直射日光を避け、涼しい場所に保存してください。
            <br />
        </p>
        <p>
            <b>■ご注意</b>
            <br />
            ・本品は天然成分原料を使用しているため、色ムラ、退色等になる場合がありますが、品質に変わりありません。
            <br />
            ・体質に合わないと思われるときには、量を減らすか休止して下さい。
            <br />
            ・疾病などで治療中の方、アレルギー体質の方はお召し上がる前に医師にご相談ください。
            <br />
            ・本品は吸湿性が高く不定形造粒のため、粒は均一ではありません。
            <br />
            ・開封後はなるべくお早めにお召し上がりください。
            <br />
            ・食生活は、主食、主菜、副菜を基本に、食事のバランスを。
            <br />
            <b>■製造国&nbsp;&nbsp;&nbsp;&nbsp;日本</b>
        </p>
    </Box>
);

export default fz;
