// material-ui
import { Box, Typography } from '@mui/material';

import case1 from 'assets/images/case-2/1.png';
import case2 from 'assets/images/case-2/2.png';
import case3 from 'assets/images/case-2/3.png';
import case4 from 'assets/images/case-2/4.png';
import case5 from 'assets/images/case-2/5.png';
import case6 from 'assets/images/case-2/6.png';
import case7 from 'assets/images/case-2/7.png';
import case8 from 'assets/images/case-2/8.png';
import case9 from 'assets/images/case-2/9.png';
import case10 from 'assets/images/case-2/10.png';
import case11 from 'assets/images/case-2/11.png';
import case12 from 'assets/images/case-2/12.png';
import case13 from 'assets/images/case-2/13.jpeg';
import case14 from 'assets/images/case-2/14.jpeg';
import case15 from 'assets/images/case-2/15.jpeg';
import case16 from 'assets/images/case-2/16.jpeg';

const case4Page = (
    <Box sx={{ maxWidth: { sx: '100%', sm: '800px' } }}>
        <Typography sx={{ mb: 2, fontWeight: 500 }}>取扱業務</Typography>
        <Typography sx={{ mb: 1 }}>1、輸出入</Typography>
        <Typography sx={{ mb: 1 }}>2、物流</Typography>
        <Typography sx={{ mb: 1 }}>3、通関</Typography>
        <Typography sx={{ mb: 1 }}>4、伝統貿易：</Typography>
        <Typography sx={{ mb: 2 }}>① 金属、電子部品および機器部品、鋳造製</Typography>
        <img src={case1} alt="" className="halfWidth center" sx={{ mb: 4, mt: 2 }} />
        <Typography sx={{ mb: 2 }}>② 建材、木材、各種木工製品等</Typography>
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>エルボ カバー</Typography>
        <img src={case2} alt="" className="halfWidth center" sx={{ mb: 4, mt: 2 }} />
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>木製ドア</Typography>
        <img src={case3} alt="" className="halfWidth center" />
        <Typography>③ 機械設備（建設、医療）およびエンジン</Typography>
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>高速道路用ガードレール</Typography>
        <img src={case4} alt="" className="halfWidth center" />
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>高速道路用眩光防止板</Typography>
        <img src={case5} alt="" className="halfWidth center" />
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>高速道路用車線分離標</Typography>
        <img src={case6} alt="" className="halfWidth center" />
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>高速道路用凍結防止剤</Typography>
        <img src={case7} alt="" className="halfWidth center" />
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>各種機械設備</Typography>
        <img src={case8} alt="" className="halfWidth center" />
        <Typography sx={{ mb: 2, mt: 2 }}>④ 家庭用及び業務用タオル類等の繊維製品</Typography>
        <img src={case9} alt="" className="halfWidth center" sx={{ mb: 4, mt: 2 }} />
        <Typography>5、デジタル貿易：</Typography>
        <Typography sx={{ textAlign: 'center', mb: 1, mt: 2 }}>雑貨、 化粧品、健康食品</Typography>
        <img src={case10} alt="" className="halfWidth" />
        <img src={case11} alt="" className="halfWidth" />
        <img src={case12} alt="" className="halfWidth" />
        <img src={case13} alt="" className="fullWidth" />
        <img src={case14} alt="" className="fullWidth" />
        <img src={case15} alt="" className="fullWidth" />
        <img src={case16} alt="" className="fullWidth" />
        <Typography sx={{ mb: 2, mt: 2, fontWeight: 500 }}>取引形態</Typography>
        <Typography sx={{ mb: 1 }}>B2B（Business to Business）</Typography>
        <Typography sx={{ mb: 1 }}>B2C（Business to Customer）</Typography>
        <Typography sx={{ mb: 1 }}>D2C（Direct to Consumer）</Typography>
        <Typography sx={{ mb: 1 }}>C2M（Consumer to Manufacturer）</Typography>
        <Typography sx={{ mb: 2 }}>O2O（Online to Offline)</Typography>
    </Box>
);

export default case4Page;
