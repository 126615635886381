import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import { MENU_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        width: '100%'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'block', md: 'none' }, p: 2, flexGrow: 1 }}>
                    <LogoSection height={18} handler={itemHandler} />
                </Box>
                <ButtonBase sx={{ borderRadius: 0, overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.primary.light,
                                color: theme.palette.primary.dark
                            },
                            borderRadius: 0,
                            width: '58px',
                            height: '58px',
                            flexDirection: 'column'
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.8rem" />
                        <Typography sx={{ fontSize: '12px' }}>MENU</Typography>
                    </Avatar>
                </ButtonBase>
            </Box>
        </Box>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
