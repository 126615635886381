import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import goodsReducer from './goodsReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    goods: goodsReducer
});

export default reducer;
